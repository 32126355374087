import React from "react";
import {
  Button,
  ImageBoxOne,
  SecTitle,
  SecSubTitle,
  InfoMedia,
} from "../../components";

const AboutOne = ({ className }) => (
  <div className={`position-relative ${className || ""}`}>
    {/* <div className="about-shape1 d-none d-xl-block">HPF</div> */}
    <div className="container z-index-common">
      <div className="row gx-60">
        <div className="col-lg-6 col-xl-5 mb-50 mb-lg-0">
          <ImageBoxOne
            imageOne="https://ik.imagekit.io/nce63ejyh/HPF/pikaso_texttoimage_35mm-film-photography-marine-fish-oil-industry-hig.jpeg?updatedAt=1724480115642"
            imageTwo="https://ik.imagekit.io/nce63ejyh/HPF/sec2.png?updatedAt=1724481203566"
            // video="https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1"
          />
        </div>
        <div className="col-lg-6 col-xl-7 align-self-center">
          <SecSubTitle>
            <i className="fas fa-bring-forward" />
            Get Best Marine Products
          </SecSubTitle>
          <SecTitle className="h1">
            Empowering Your Marine Product Needs
          </SecTitle>
          <p className="mb-4 mt-1 pb-3">
            At HPF Marine Line, we believe in delivering more than just premium
            marine products; we offer solutions that enhance your business. Our
            fish oil and fish powder products are crafted to meet the highest
            industry standards, ensuring that your business benefits from the
            finest quality ingredients sourced from the sea.
          </p>
          <InfoMedia
            image="images/icon/tel-1-1.png"
            title="24 HOURS SERVICE AVAILABLE"
            info="Call Us: +91 7992999966"
            className="call-media"
          />
          <Button path="/about">
            About Us
            <i className="far fa-long-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default AboutOne;

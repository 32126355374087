import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
  HeaderTwo,
  ServiceFour,
  AboutSeven,
  // TeamTwo,
  // TestimonialThree,
  CtaOne,
  // BlogFour,
  FooterOne
} from '../containers';

const About = () => (
  <Fragment>
    <Seo title="About Us" />
    <HeaderTwo />
    <Breadcrumb pageName="About Us" bgImage="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(5)%201.png?updatedAt=1724597914532" />
    <ServiceFour className="space-top space-extra-bottom" />
    <AboutSeven />
    {/* <TeamTwo className="space-top space-extra-bottom" /> */}
    {/* <TestimonialThree className="space-top space-extra-bottom" /> */}
    <CtaOne className="space" />
    {/* <BlogFour className="space-top space-extra-bottom" /> */}
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default About;
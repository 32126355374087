import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title }) => {
  return (
    <Helmet>
      <title>HPF Marine Line</title>
    </Helmet>
  );
};

export default SEO;

import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
  HeaderTwo,
  ContactTwo,
  FooterOne
} from '../containers';

const Contact = () => (
  <Fragment>
    <Seo title="Contact Us" />
    <HeaderTwo />
    <Breadcrumb style={{ color: '#122A36' }} pageName="Contact Us" bgImage="https://ik.imagekit.io/nce63ejyh/HPF/top-view-fish-ice-cubes.jpg?updatedAt=1724581311155" />
    <ContactTwo className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default Contact;
import React from "react";
import {
  HeaderTop,
  HeaderSticky,
  InfoMedia,
  Logo,
  MainMenu,
  FormFour,
  MobileMenu,
  IconLink,
} from "../../components";

const HeaderTwo = () => (
  <header className="vs-header header-layout1">
    <HeaderTop>
      <HeaderTop.Left>
        <p className="note_style1">
          {/* <span className="note_title">New Arrivals::</span> */}
          Nutrient-Rich Marine Products for a Healthier You
        </p>
      </HeaderTop.Left>
      <HeaderTop.Right>
        <IconLink className="header-social style-white" title="Follow Us On:">
          <IconLink.Item
            icon="fab fa-facebook-f"
            path="https://www.facebook.com/share/L8bsohB6oS9N3j1n/?mibextid=LQQJ4d"
          />
          {/* <IconLink.Item icon="fab fa-twitter" path="/" /> */}
          <IconLink.Item
            icon="fab fa-instagram"
            path="https://www.instagram.com/hpf_marineline?igsh=MWs4Mjd5MWNnYXhrZw=="
          />
          {/* <IconLink.Item icon="fab fa-behance" path="/" /> */}
          <IconLink.Item icon="fab fa-youtube" path="/" />
        </IconLink>
      </HeaderTop.Right>
    </HeaderTop>
    <div className="container">
      <div className="menu-top">
        <div className="row justify-content-between align-items-center gx-sm-0">
          <div className="col">
            <Logo image="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003596.png?updatedAt=1724600818071" />
          </div>
          <InfoMedia
            className="header-media col-auto"
            icon="fas fa-phone-alt"
            title="Call Anytime 24/7"
            info={<a href="tel:+917992999966">+91 7992999966</a>}
          />
          <InfoMedia
            className="header-media col-auto d-none d-lg-flex"
            icon="fas fa-envelope"
            title="Mail Us For Support"
            info={
              <a href="mailto:contact@hpfmarine.com">contact@hpfmarine.com</a>
            }
          />
          <InfoMedia
            className="header-media col-auto d-none d-xl-flex"
            icon="fas fa-map-marker-alt"
            title="Office Address"
            info="1st floor Adonai Tower  Mangalore"
          />
        </div>
      </div>
    </div>
    <HeaderSticky>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <MainMenu className="menu-style1 d-none d-lg-block" />
            <MobileMenu />
          </div>
          <div className="col-auto">
            <FormFour />
          </div>
        </div>
      </div>
    </HeaderSticky>
  </header>
);

export default HeaderTwo;

import React from "react";
import { SecSubTitle, SecTitle, ProgressOne } from "../../components";

const AboutTwo = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-5 col-xxl-auto mb-30 pb-20 pb-lg-0">
          <img
            src="https://ik.imagekit.io/nce63ejyh/HPF/flat-lay-delicious-seafood-assortment%201.png?updatedAt=1724579300669"
            alt="Skill"
          />
        </div>
        <div className="col-lg-7 col-xxl-6 me-xl-auto">
          <SecSubTitle>
            <i className="fas fa-bring-forward" />
            Mastering Excellence in Marine Solutions
          </SecSubTitle>
          <SecTitle className="h1">
            Connecting People and Transforming Marine Solutions
          </SecTitle>
          <p className="mb-4 pb-1">
            HPF Marine Line excels through our expertise in sustainable
            sourcing, commitment to quality, and innovative approach to product
            development. Our deep understanding of marine resources, combined
            with our dedication to customer satisfaction...
          </p>
          <ProgressOne title="Fish Powder" now={90} />
          <ProgressOne title="Fish oil" now={88} />
          <ProgressOne title="Fish Soluble" now={60} />
        </div>
      </div>
    </div>
  </div>
);

export default AboutTwo;

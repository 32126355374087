import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
  HeaderTwo,
  ServiceFive,
  CtaOne,
  ProcessOne,
  TestimonialOne,
  FooterOne
} from '../containers';

const Service = () => (
  <Fragment>
    <Seo title="Services" />
    <HeaderTwo />
    <Breadcrumb pageName="Services" bgImage="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(4).jpg?updatedAt=1724597052748" />
    <ServiceFive className="space-top space-extra-bottom" />
    <CtaOne className="space" />
    <ProcessOne className="space-top space-extra-bottom" />
    <TestimonialOne className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default Service;
import React from "react";
import Slider from "react-slick";
import { Button } from "../../components";
import styled from "styled-components";

const HideLeftArrow = styled.div`
  .slick-prev {
    display: none !important;
  }
`;

const HeroOne = () => {
  const HEROCONTENT = [
    {
      image:
        "https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration.jpg?updatedAt=1723751874628",
      title: (
        <>
          Best Marine Products
          {/* <span className="hero-innertext">Get Best It Solution 2022</span>{" "} */}
          <br /> Premium Fish Oil & Powder.
        </>
      ),
      text: "Discover the pure essence of the ocean with our premium fish oil and fish powder. Sustainably sourced and packed with nutrients, our marine products are crafted to support your optimal health and well being.",
      btn1: { text: "About Us", path: "/about" },
      btn2: { text: "Contact Us", path: "/contact" },
    },
    {
      image:
        "https://ik.imagekit.io/nce63ejyh/HPF/HPF_HERO2.jpg?updatedAt=1723762179769",
      title: (
        <>
          Best Marine Fish Oil and Powder
          {/* <span className="hero-innertext">PROVIDE FREE CONSULTATION</span>{" "} */}
          <br /> for Superior Vitality and Well-being.
        </>
      ),
      text: "Discover the pure essence of the ocean with our premium fish oil and fish powder. Sustainably sourced and packed with nutrients, our marine products are crafted to support your optimal health and well being.",
      btn1: { text: "About Us", path: "/about" },
      btn2: { text: "Contact Us", path: "/contact" },
    },
    {
      image:
        "https://ik.imagekit.io/nce63ejyh/HPF/HPF-Hero3.jpg?updatedAt=1723762179754",
      title: (
        <>
          Best Marine Fish Oil and Powder
          {/* <span className="hero-innertext">Best Marine Products</span>{" "} */}
          <br /> for Peak Strength and Wellness.
        </>
      ),
      text: "Discover the pure essence of the ocean with our premium fish oil and fish powder. Sustainably sourced and packed with nutrients, our marine products are crafted to support your optimal health and well being.",
      btn1: { text: "About Us", path: "/about" },
      btn2: { text: "Contact Us", path: "/contact" },
    },
  ];

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <HideLeftArrow>
      <Slider className="hero-layout1" {...settings}>
        {HEROCONTENT.map((slide, index) => (
          <div key={index}>
            <div className="hero-inner">
              <div className="hero-shape1"></div>
              <div className="hero-shape2"></div>
              <div
                className="hero-bg background-image"
                style={{ backgroundImage: `url(${slide.image})` }}
              ></div>
              <div className="container">
                <div className="hero-content">
                  <h1 className="hero-title">{slide.title}</h1>
                  <p className="hero-text">{slide.text}</p>
                  <div className="hero-btns">
                    <Button path={slide.btn1.path}>
                      {slide.btn1.text}
                      <i className="far fa-arrow-right" />
                    </Button>
                    <Button path={slide.btn2.path} className="style2">
                      {slide.btn2.text}
                      <i className="far fa-arrow-right" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </HideLeftArrow>
  );
};

export default HeroOne;

import React from "react";
import Slider from "react-slick";

const BrandOne = ({ ...restProps }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div {...restProps}>
      <div className="container">
        <div className="sec-line-wrap">
          <div className="sec-line"></div>
          <h2 className="sec-title2">Our Trusted Clients</h2>
          <div className="sec-line"></div>
        </div>
        <Slider className="row text-center" {...settings}>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003606.svg?updatedAt=1724601795874"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003606.svg?updatedAt=1724601795874"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003606.svg?updatedAt=1724601795874"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003606.svg?updatedAt=1724601795874"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/Group%201000003606.svg?updatedAt=1724601795874"
              alt="brands"
            />
          </div>
          {/* <div><img src="images/brand/br-1-6.png" alt="brands"/></div> */}
        </Slider>
      </div>
    </div>
  );
};

export default BrandOne;

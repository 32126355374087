import React from "react";
import { SecSubTitle, SecTitle, InfoMedia, Button } from "../../components";

const AboutSeven = () => (
  <div
    className="background-image"
    style={{ backgroundImage: "url(images/bg/about-bg-5-1.jpg)" }}
  >
    <div className="container container-style1">
      <div className="row flex-row-reverse align-items-center gx-70">
        <div className="col-lg-6 col-xl">
          <img
            src="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(6)%201.png?updatedAt=1724598284998"
            alt="aboutthumb"
          />
        </div>
        <div className="col-lg-6 col-xl-auto">
          <div className="about-box2">
            <SecSubTitle>
              <i className="fas fa-bring-forward" />
              Get Best Marine Products
            </SecSubTitle>
            <SecTitle className="text-capitalize h1">
              Inspiring Marine Solutions for Your Business
            </SecTitle>
            <p>
              Dynamically enhance marine product offerings through
              client-focused solutions. Authoritatively expand market reach by
              delivering high-quality, sustainably sourced content tailored to
              your needs.
            </p>
            <div className="row gx-0 align-items-center flex-row-reverse justify-content-end mt-sm-3 pt-sm-3 mb-30 pb-10">
              <div className="col-sm-auto">
                <p className="author-degi">Founder & CEO</p>
                <h3 className="h5 author-name">Sarfraz Nawaz</h3>
              </div>
              <div className="col-sm-auto">
                <InfoMedia
                  className="about-call"
                  icon="fas fa-phone-alt"
                  title="Call To Ask Any Queary"
                  info={<a href="tel:+917992999966">+91 7992999966</a>}
                />
              </div>
            </div>
            <Button path="/service">
              Order Service
              <i className="far fa-long-arrow-right" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutSeven;

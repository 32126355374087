import React from "react";

const Widget = ({ widgetTitle, className, children, style }) => (
  <div className={`widget ${className || ""}`}>
    {widgetTitle ? (
      <h3 style={style} className="widget_title">
        {widgetTitle}
      </h3>
    ) : (
      ""
    )}
    {children}
  </div>
);

export default Widget;

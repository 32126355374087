import React from "react";
import { Button } from "../";

const FormFive = () => {
  const formHandle = (event) => {
    event.preventDefault(); // Prevent the form from submitting the traditional way
    handleButtonClick(); // Call the function to handle email creation and sending
  };

  const handleButtonClick = () => {
    // Fetch form values
    const fullName = document.getElementById("fullName").value;
    const email = document.getElementById("email").value;
    const number = document.getElementById("number").value;
    const subject = document.getElementById("subject").value;
    const note = document.getElementById("note").value;

    // Construct email body with line breaks
    const emailBody = `Full Name: ${fullName}\nEmail: ${email}\nNumber: ${number}\nProject Type: ${subject}\nNote: ${note}`;

    // Open default email client with pre-filled email
    window.location.href = `mailto:contact@hpfmarine.com?subject=Enquiries&body=${encodeURIComponent(
      emailBody
    )}`;
  };

  return (
    <form action="#" className="vs-contact-form" onSubmit={formHandle}>
      <div className="row gx-20">
        <div className="col-md-6 form-group">
          <input type="text" placeholder="Your Name" id="fullName" required />
          <i className="fal fa-user" />
        </div>
        <div className="col-md-6 form-group">
          <input type="email" placeholder="Email Address" id="email" required />
          <i className="fal fa-envelope" />
        </div>
        <div className="col-12 form-group">
          <input
            type="number"
            placeholder="Phone Number"
            id="number"
            required
          />
        </div>
        <div className="col-12 form-group">
          <select id="subject" required>
            <option value="">Select subject</option>
            <option value="Fish Powder">Fish Powder</option>
            <option value="Fish Oil">Fish Oil</option>
            <option value="Fish Soluble">Fish Soluble</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="col-12 form-group">
          <textarea placeholder="Type Your Message" id="note" required />
        </div>
        <div className="col-12">
          <Button onClick={handleButtonClick}>
            Submit Message
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FormFive;

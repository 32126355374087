import React from "react";
import { FeatureBoxOne } from "../../components/";
import Slider from "react-slick";

const FeatureOne = ({ className }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={`feature-wrap1 ${className || ""}`}>
      <div className="container">
        <Slider className="row" {...settings}>
          <FeatureBoxOne
            title="Fish Powder"
            text="Our fish powder is rich in proteins, omega-3s, and essential nutrients. Crafted from the finest fish, it supports your health with unmatched purity and potency."
            image="https://ik.imagekit.io/nce63ejyh/HPF/proteins.png?updatedAt=1723755466292"
            path="/service-details"
          />
          <FeatureBoxOne
            title="Fish Oil"
            text="Our fish oil is a rich source of omega-3 fatty acids and essential nutrients. Sourced from the highest quality fish, it delivers pure, potent nutrition to support your heart, brain, and overall well-being."
            image="https://ik.imagekit.io/nce63ejyh/HPF/fish-oil.png?updatedAt=1723755910944"
            path="/service-details"
          />
          <FeatureBoxOne
            title="Fish Soluble"
            text="Our fish soluble provides essential nutrients and concentrated benefits for overall health, vitality, and resilience. Ideal for supplements, it offers pure, potent marine nutrition."
            image="https://ik.imagekit.io/nce63ejyh/HPF/fish-oil%20(1).png?updatedAt=1723755910970"
            path="/service-details"
          />
        </Slider>
      </div>
    </div>
  );
};

export default FeatureOne;

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

// FAQ Data
import faqData from "../../data/faq.json";

const ServiceSingle = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-8">
          <div className="mb-3 pb-3">
            <img
              src="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(9).jpg?updatedAt=1724611070326"
              alt="Project"
            />
          </div>
          <h2 className="h4">Marine Solutions and Business Excellence</h2>
          <p>
            At HPF Marine Line, we holistically benchmark our marine products to
            ensure they meet the highest standards of quality and empowerment.
            We seamlessly visualize and implement innovative, web-ready
            solutions while driving extensive sustainability initiatives. Our
            commitment to excellence allows us to completely unleash
            frictionless, end-to-end marine services, ensuring our products are
            delivered with precision and reliability.
          </p>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(2)%201.png?updatedAt=1724577836636"
                alt="project"
                className="w-100"
              />
            </div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Premium Marine Products
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Sustainable Sourcing Practices
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Customized Marine Solutions
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    25 Years of Industry Expertise
                  </List.Item>
                </List>
              </div>
            </div>
          </div>
          <h3 className="h5">The Challenge of Marine Innovation</h3>
          <p>
            At HPF Marine Line, we face the challenge of marine innovation
            head-on by seamlessly integrating sustainable practices into every
            aspect of our operations. We dynamically innovate our marine
            solutions, utilizing cutting-edge technology and advanced processing
            techniques to enhance our product offerings continually.
          </p>
          <p>
            By empowering industry standards, we deliver impactful and
            cost-effective solutions that cater to the evolving needs of global
            markets. Our approach optimizes market reach by synergistically
            expanding marine product distribution, ensuring that excellence and
            sustainability are at the core of our supply chain.
          </p>
          <div className="row pt-3 mb-30 pb-10 gy-30">
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/HPF/fish-oil-capsules-glass-bowl.jpg?updatedAt=1724611468412"
                alt="project"
              />
            </div>
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/HPF/therapy-relaxing-spa-brown-sand.jpg?updatedAt=1724611612214"
                alt="project"
              />
            </div>
          </div>
          <Accordion
            defaultActiveKey={faqData[0].questionId}
            className="accordion-style1 layout2"
          >
            {faqData.map((item) => (
              <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>
                  <p>{item.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="col-lg-4">
          <SidebarTwo />
        </div>
      </div>
    </div>
  </div>
);

export default ServiceSingle;

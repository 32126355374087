import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
  HeaderTwo,
  ServiceSingle,
  FooterOne
} from '../containers';

const ServiceDetails = () => (
  <Fragment>
    <Seo title="Services Details" />
    <HeaderTwo />
    <Breadcrumb pageName="Services Details" bgImage="https://ik.imagekit.io/nce63ejyh/HPF/photorealistic-wild-tuna-day-celebration%20(7).jpg?updatedAt=1724610210162" />
    <ServiceSingle className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default ServiceDetails;
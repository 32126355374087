import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ image, className, style }) => (
  <Link className={`vs-logo ${className || ""}`} to="/">
    <img src={image} alt="Logo" style={style} />
  </Link>
);

export default Logo;
